
.order-detail-page {
    margin-top: 190px;
    display: flex;
    justify-content: center;
    height: 60vh;
    align-items: center; 
    background-color: white; 
}

.order-detail-container {
    max-width: 700px;
    padding: 30px; 
    border-radius: 8px; 
    box-shadow: 0 0 15px rgba(0,0,0,0.1); 
    background-color: white;
}

.details-section {
    display: flex;
    flex-wrap: wrap;
    
}

.details-section p {
    width: 45%; 
    margin: 10px 0;
}

.order-details-for-text {
    border-bottom: 2px solid aquamarine; 
    padding-bottom: 15px;
    margin-bottom: 20px;
}

@media (max-width: 750px) {
    .order-detail-page {
        height: 82vh;
    }

    .order-detail-container {
        max-width: 260px;
        font-size: 12px;
    }
    .details-section {
        flex-direction: column;
    }

}
