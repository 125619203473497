.login-container {
  
  height: 90%;
  }
  
  .logged-out-message-container {
    display: flex;
    flex-direction: column;
    margin-left: 20%;
    height: 60vh;
    font-family: 'Poppins', sans-serif;
    margin-top: 100px;
    font-size: 30px;
    padding: 20px;
    border-radius: 5px;

    background-color: white;
  }

  .sign-in-prompt {
    margin-left: 20%;
  }

  .sign-in-button {
    max-width: 160px;
    background-color: #b82717; 
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s, transform 0.3s;
    
    box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
    margin-right: 0px;
  }

  .sign-in-button:hover {
    background-color: #ff1900; 
    transform: scale(1.05);
    font-weight: 600;   
  }
  .sign-out-button {
    max-width: 90px;
    background-color: #b82717; 
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s, transform 0.3s;
   
    box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
    margin-right: 0px;
}


.sign-out-button:hover {
    background-color: #ff1900; 
    transform: scale(1.05);
    font-weight: 600;     
}

.sign-out-button-container {
  width: 80%;
  display: flex;
  flex-direction: row-reverse;
}


  .logged-in-message-container {
    display: flex;
    align-items: center;
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: white;
  }
  
  .sign-in-with-google {
    max-width: 40px;
    margin-top: 10px;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    z-index: 9999;
  }
  

.orders-container {
  width: 80%;
  background-color: white;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  padding: 15px;
  margin: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  max-height: 800px;
  overflow-y: scroll;

}

.order {
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
  color: black;
}

.order span {
  display: block;
  margin: 5px 0;
}

.order-title {
  font-size: 40px;
  margin-right: 60%;
  font-weight: bold;
  color: red;
  margin-bottom: 5px;
}

.order-part-list {
  list-style: none;
}

.order-part-list li{
    border-bottom: 1px solid rgb(204, 195, 195);
}

@media (max-width: 768px) {
  .order-title {
    font-size: 30px;
    margin-left: 50px;
  }
}

.loading-text {
  margin-top: 400px;
  font-size: 40px;
  margin-bottom: auto;
  margin-right: auto;
  margin-left: 50%;
}
