.footer-container {
    background-color: #333;
    padding: 40px 0;
    color: #fff;
    margin-top: 30px;
    width: 100%;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.footer-section {
    flex: 1;
    margin-right: 20px;
}

.footer-section h4 {
    border-bottom: 2px solid #f36f6f;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.footer-section ul {
    list-style-type: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 10px;
}

.footer-section ul li a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-section ul li a:hover {
    color: #f36f6f;
}

.footer-bottom {
    text-align: center;
    margin-top: 40px;
    font-size: 14px;
}

@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
    }

    .footer-section {
        margin-right: 0;
        margin-bottom: 20px;
    }
}