.choose-item-container {
    
    color: rgb(159, 94, 15);
    color: black;
    padding: 10px;
    max-width: 900px;
    font-family: 'sans-serif', 'Poppins';
}

.choose-item-text {
    color: white;
}

.choose-component-button-container {
    display: flex;
    flex-wrap: wrap;
}

.component-button {
    font-family: 'sans-serif', 'Poppins';
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 16px;
    margin-right: 10px;
    height: 50px;
    padding: 5px;
    margin-bottom: 6px;
    margin-top: 3px;
    border-radius: 10px;
    border: 1px solid black;
    cursor: pointer;
    background-color: white;
}

.choose-item-label {
    color: rgb(8, 8, 56);
}

.component-button:hover {
    transform: scale(1.04);
}

.choose-component-icon {
    width: 42px;
    margin-right: 5px;
}

@media (min-width: 1100px) {
    .component-button {
        height: 65px;
        font-size: 20px;
    }

}

