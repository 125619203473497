.select-box {
    display: flex;
    flex-direction: column;
}

.choose-specifications-container {
    background-color: rgb(253, 253, 253);
    margin-top: 20px;
    color: black;
    border-radius: 15px;
    border: 2.5px solid rgb(60, 54, 16);
}

.select-box-choose {
    display: flex;
    flex-wrap: wrap;
}

.label-and-button {
    color: black;
    padding: 20px;
    display: flex;
    flex-direction: column;
}


.select-dropdown-box {
    font-size: 16px;
    color: rgb(8, 8, 56);
    font-family: 'Poppins', sans-serif;
    width: 190px;
    height: 60px;
    border-radius: 10px;
    border: 1px solid rgb(249, 0, 0);
    transition: background-color 0.3s, transform 0.3s;
    cursor: pointer;
}

.select-dropdown-box:hover {
    font-weight: 500;
    transform: scale(1.04);
    
}

.select-box-add-part-to-cart {
    font-size: 17px;
    font-family: 'Poppins', sans-serif;
    width: 190px;
    height: 60px;
    margin: 0px 0px 10px 30px;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
    transition: background-color 0.3s, transform 0.3s;
    border-radius: 20px;
    color: white;
    background-color: rgb(244, 239, 239);
    color: black;
    cursor: pointer;
}

.select-box-add-part-to-cart:hover {
    background-color: #ff1900;
    transform: scale(1.06);
    color: azure;    
  }

@media (max-width: 768px) {
    .select-dropdown-box {
        font-size: 14px;
        width: 140px;
        height: 50px;
    }
}

.add-to-cart-message {
    color: black;
    padding: 20px;
}

.coming-soon-container {
    color: black;
    padding: 30px;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    background-color: rgba(253, 253, 253, 0.7);  
    border-radius: 15px;
    margin: 20px;
}

@media (max-width: 768px) {
    .coming-soon-container {
        font-size: 16px;
        padding: 20px;
    }
}
